<app-header-with-back-button [titleTranslationKey]="'aboutUs.title'"></app-header-with-back-button>

<img src="assets/about-us/about_us_teaser.jpg" alt="about us" class="teaser" />

<div class="about-us-content">
  <p [innerHTML]="'aboutUs.p1' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p2' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p3' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p4' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p5' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p6' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p7' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p8' | translate | newLine"></p>
  <p [innerHTML]="'aboutUs.p9' | translate | newLine"></p>
</div>
