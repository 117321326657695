import { Injectable } from '@angular/core';
import { map, Observable, of, pluck, throwError } from 'rxjs';
import { Language, LanguageRawDto } from '../../models/language';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';

const TRANSLATION_LOCAL_FILE_URL = 'TRANSLATION_LOCAL_FILE_URL';
const TRANSLATIONS_LOCAL_STORAGE_KEY = 'TRANSLATIONS_LOCAL_STORAGE_KEY';

@Injectable({
  providedIn: 'root'
})
export class StaticTranslationService {
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  getLanguages(): Observable<Language[]> {
    return this.http
      .get<LanguageRawDto[]>(this.appConfigService.dictionaryUrl)
      .pipe(map(rawLanguages => rawLanguages.map(Language.fromDto)));
  }

  getRemoteTranslation(lang: string): Observable<any> {
    const options = { params: new HttpParams().set('nested', '1') };
    return this.http.get<any>(this.appConfigService.translationsUrl + lang, options);
  }

  getLocalFileLanguageGroup(): Observable<Language[]> {
    return this.http.get<{ fallbackLanguages: Language[] }>('').pipe(pluck('fallbackLanguages'));
  }

  getLocalFileTranslation(): Observable<any> {
    return this.http.get<any>(TRANSLATION_LOCAL_FILE_URL).pipe(map(({ data }) => data));
  }

  getLocalTranslation(lang: string): Observable<any> {
    const localStorageTranslations = localStorage.getItem(TRANSLATIONS_LOCAL_STORAGE_KEY);

    if (!localStorageTranslations) {
      return throwError(new Error('No local translations'));
    }

    const localStorageLangTranslations = JSON.parse(localStorage.getItem(TRANSLATIONS_LOCAL_STORAGE_KEY))[lang];
    if (!localStorageLangTranslations) {
      return throwError(new Error('No local translations for language ' + lang));
    }

    return of(localStorageLangTranslations);
  }

  setLocalTranslation(lang: string, translation: any): void {
    //Don't user local store to avoid writing the legal / cookie banner
    // localStorage.setItem(
    //   TRANSLATIONS_LOCAL_STORAGE_KEY,
    //
    //   JSON.stringify({
    //     ...JSON.parse(localStorage.getItem(TRANSLATIONS_LOCAL_STORAGE_KEY)),
    //     [lang]: { ...translation }
    //   })
    // );
  }
}
