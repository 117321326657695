import { Component } from '@angular/core';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent {
  openPrivacyFile() {
    window.open('/assets/privacy/2023-10-11_privacy-policy-about.pdf', '_blank');
  }
}
