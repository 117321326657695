import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-with-back-button',
  templateUrl: './header-with-back-button.component.html',
  styleUrls: ['./header-with-back-button.component.scss']
})
export class HeaderWithBackButtonComponent {
  @Input()
  titleTranslationKey: string;

  @Input()
  title: string;

  @Input()
  backRoute = ['/..'];

  @Input()
  backButtonTranslationKey = 'backButton';
}
