import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfigService } from '../../../../core/app-config.service';
import { CareerConfig } from '../../../../models/config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  openPositions: any[];
  cities: string[];
  careerSettings: CareerConfig;
  childrenId: number;

  constructor(private destroyRef: DestroyRef, private route: ActivatedRoute, private router: Router, private config: AppConfigService) {
    this.careerSettings = this.config.careerSettings;
    this.openPositions = this.careerSettings.jobsSelections;
    this.cities = this.openPositions.map(openPosition => openPosition.place);
    this.cities = this.cities.filter((place, index) => this.cities.indexOf(place) === index);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(res => res instanceof NavigationEnd)
      )
      .subscribe(() => this._navigationEnd());
    this._navigationEnd();
  }

  open(link: string) {
    this.router.navigate(['jobs', link]);
  }

  private _navigationEnd() {
    this.childrenId = this.route.snapshot?.firstChild?.data?.id || null;
  }
}
