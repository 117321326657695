export class Language {
  nativeName: string;
  lnCode: string;

  constructor(lnCode: string, nativeName: string) {
    this.nativeName = nativeName;
    this.lnCode = lnCode;
  }
}

export class LanguageGroup {
  languages: Array<Language>;

  constructor(languages: Array<Language>) {
    this.languages = languages;
  }
}

export const LANGUAGE_GROUP_LATIN = new LanguageGroup([
  new Language('af', 'Afrikaans'),
  new Language('id', 'bahasa Indonesia'),
  new Language('ms', 'Bahasa Melayu'),
  new Language('bs', 'bosanski'),
  new Language('ca', 'català'),
  new Language('cy', 'Cymraeg'),
  new Language('da', 'dansk'),
  new Language('de', 'Deutsch'),
  new Language('et', 'eesti'),
  new Language('en', 'English'),
  new Language('en_UK', 'English, United Kingdom'),
  new Language('es', 'Español'),
  new Language('fil', 'Filipino'),
  new Language('fr', 'français'),
  new Language('fr_CA', 'français, Canada'),

  new Language('sm', 'Gagana Sāmoa'),
  new Language('mww', 'Hmong Daw'),
  new Language('hr', 'hrvatski'),
  new Language('is', 'íslenska'),
  new Language('it', 'Italiano'),
  new Language('sw', 'Kiswahili'),
  new Language('ht', 'kreyòl ayisyen'),
  new Language('lv', 'latviešu'),
  new Language('to', 'lea fakatonga'),
  new Language('lt', 'lietuvių'),
  new Language('hu', 'magyar'),
  new Language('mg', 'Malagasy'),
  new Language('mt', 'Malti'),
  new Language('nl', 'Nederlands'),
  new Language('nb', 'norsk'),

  new Language('pl', 'polski'),
  new Language('pt-PT', 'Português (Portugal)'),
  new Language('pt', 'Português (Brasil)'),
  new Language('otq', 'Querétaro Otomi'),
  new Language('ro', 'Română'),
  new Language('sq', 'Shqip'),
  new Language('sk', 'Slovenčina'),
  new Language('sl', 'Slovenščina'),
  new Language('sr-Latn', 'srpski'),
  new Language('fi', 'suomalainen'),
  new Language('sv', 'svenska'),
  new Language('ty', 'te reo tahiti'),
  new Language('vi', 'Tiếng Việt'),
  new Language('tr', 'Türkçe'),
  new Language('fj', 'Vakaviti'),
  new Language('yua', 'Yucatec Maya')
]);

export const LANGUAGE_GROUP_CYRILLIC = new LanguageGroup([
  new Language('cs', 'čeština'),
  new Language('el', 'Ελληνικά'),
  new Language('bg', 'български'),
  new Language('ru', 'русский'),
  new Language('sr-Cyrl', 'српски'),
  new Language('uk', 'Українська')
]);

export const LANGUAGE_GROUP_OTHER = new LanguageGroup([
  new Language('he', 'עברית'),
  new Language('ur', 'اردو'),
  new Language('ar', 'العربية'),
  new Language('fa', '‏فارسی‏'),
  new Language('hi', 'हिंदी'),
  new Language('bn', 'বাংলা'),
  new Language('ta', 'தமிழ்'),
  new Language('te', 'తెలుగు'),
  new Language('th', 'ไทย'),
  new Language('zh-Hans', '简体中文'),
  new Language('ja', '日本語'),
  new Language('ko', '한국어'),
  new Language('zh-Hant', '繁體中文'),
  new Language('yue', '粵語 繁體中文')
]);

export const LANGUAGE_GROUPS: Array<LanguageGroup> = [LANGUAGE_GROUP_LATIN, LANGUAGE_GROUP_CYRILLIC, LANGUAGE_GROUP_OTHER];
