import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { Language, LANGUAGE_DIRECTION } from '../models/language';
import { StaticTranslationService } from './api/static-translation.service';
import { LANGUAGE_GROUPS, LanguageGroup } from './language-list';
import { TranslateService } from '@ngx-translate/core';

const LOCAL_STORAGE_KEY_INITIAL_LANGUAGE = 'LOCAL_STORAGE_KEY_INITIAL_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _uiLanguage$: ReplaySubject<string> = new ReplaySubject(1);
  private _translationLanguage: string;
  private _nativeLanguages: Language[];

  constructor(private staticTranslationService: StaticTranslationService, private translateService: TranslateService) {}

  get uiLanguageDirection$(): Observable<LANGUAGE_DIRECTION> {
    return this.uiLanguage$.pipe(
      map(languageCode => this.getLanguageByCode(languageCode)),
      catchError(() => of(null)),
      map(language => (language ? language.dir : LANGUAGE_DIRECTION.LEFT_TO_RIGHT))
    );
  }

  get uiLanguage$(): Observable<string> {
    return this._uiLanguage$ as Observable<string>;
  }

  init(): Observable<Language[]> {
    return this.staticTranslationService.getLanguages().pipe(
      //catchError(() => of([...LANGUAGE_GROUP_LATIN.languages, ...LANGUAGE_GROUP_CYRILLIC.languages, ...LANGUAGE_GROUP_OTHER.languages])),
      map(languages =>
        languages.map(language => {
          // Some custom hack to handle custom language code
          language.lnCode = language.lnCode === 'fa_PRS' ? 'fa' : language.lnCode;
          return language;
        })
      ),
      map((res: Language[]) => {
        const result: Language[] = [];
        LANGUAGE_GROUPS.forEach((languageGroup: LanguageGroup, index) => {
          languageGroup.languages.forEach(languageSort => {
            const languageRes = res.find(language => language.lnCode === languageSort.lnCode);

            if (languageRes) {
              languageRes.group = index;
              result.push(languageRes);
            }
          });
        });
        return result;
      }),
      tap(safeLanguages => (this._nativeLanguages = safeLanguages)),
      switchMap(languages => {
        const browserLanguage = this.getBrowserLanguage();
        if (this._nativeLanguages.find(({ lnCode }) => lnCode === browserLanguage)) {
          this.useLanguage(browserLanguage);
        } else {
          this.useLanguage('en');
        }
        return of(languages);
      })
    );
  }

  getTranslatedLanguages = (): Observable<Language[]> =>
    this.uiLanguage$.pipe(
      switchMap((uiLanguageCode: string) => {
        if (uiLanguageCode !== this._translationLanguage) {
          return of(
            this._nativeLanguages.map(language => language.name),
            tap((translations: string[]) => {
              this._nativeLanguages.forEach((item: Language, index: number) => {
                item.translation = translations[index];
              });
            }),
            tap(() => (this._translationLanguage = uiLanguageCode))
          );
        }
        return of(true);
      }),
      map(() => this._nativeLanguages)
    );

  setNewLanguage(languageCode: string) {
    return this.useLanguage(languageCode);
  }

  getLanguageByCode(lnCode: string): Language {
    //toLocaleLowerCase().replace("-", "_") because the dictionary returns pt-PT and the subject is with pt_pt
    return this._nativeLanguages.find(language => language.lnCode.toLocaleLowerCase().replace('-', '_') === lnCode);
  }

  private getBrowserLanguage(): string {
    return window.navigator.language ? window.navigator.language.split('-')[0] : null;
  }

  private useLanguage(lnCode: string): Observable<boolean> {
    this._uiLanguage$.next(lnCode);
    return this.translateService.use(lnCode);
  }
}
