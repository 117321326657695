import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../core/app-config.service';
import { MainContentConfig } from '../../models/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  mainContentSettings: MainContentConfig;

  languages = [
    {
      buttonLabel: 'Deutsch',
      languageCode: 'de'
    },
    {
      buttonLabel: 'ελληνικά',
      languageCode: 'el'
    },
    {
      buttonLabel: 'English',
      languageCode: 'en'
    },
    {
      buttonLabel: 'español',
      languageCode: 'es'
    },
    {
      buttonLabel: 'français',
      languageCode: 'fr'
    },
    {
      buttonLabel: 'italiano',
      languageCode: 'it'
    },
    {
      buttonLabel: 'polski',
      languageCode: 'pl'
    },
    {
      buttonLabel: 'português',
      languageCode: 'pt_pt'
    },
    {
      buttonLabel: 'português do Brasil',
      languageCode: 'pt'
    },
    {
      buttonLabel: 'русский',
      languageCode: 'ru'
    },
    {
      buttonLabel: 'svenska',
      languageCode: 'sv'
    }
  ];

  constructor(private config: AppConfigService, private router: Router) {}

  ngOnInit() {
    this.mainContentSettings = this.config.mainContentSettings;
  }

  navigate(internalLink) {
    this.router.navigate([internalLink]);
  }
}
