export enum LANGUAGE_DIRECTION {
  LEFT_TO_RIGHT = 'ltr',
  RIGHT_TO_LEFT = 'rtl'
}

export interface LanguageRawDto {
  lnCode: string;
  name: string;
  nativeName: string;
  dir: LANGUAGE_DIRECTION;
}

export class Language {
  public translation?: string;
  public group: number;

  constructor(public lnCode: string, public name: string, public nativeName: string, public dir: LANGUAGE_DIRECTION = null) {}

  static fromDto = ({ lnCode, name, nativeName, dir }: LanguageRawDto) => new Language(lnCode, name, nativeName, dir);
}

export type NativeLanguageFilter = string;
