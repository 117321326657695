<span class="content-title">{{ mainContentSettings.titleTranslationKey | translate }}</span>

<div class="links-container">
  <div *ngFor="let language of languages">
    <a
      [href]="'https://languages.akelius.com/subjects/' + language.languageCode"
      target="_blank"
      [class.language-rect]="mainContentSettings['showLanguagesButton']"
      class="language-link"
    >
      <span>{{ language.buttonLabel }}</span>
    </a>
  </div>
</div>

<img *ngIf="mainContentSettings.teaserSrc" [src]="mainContentSettings.teaserSrc" alt="Main Photo" class="teaser" />
<!--TODO: get rid of flag and of one of the tiles-columns classes when a decision is made about the style-->
<div [ngClass]="mainContentSettings.is4Columns ? 'tiles-columns-4' : 'tiles-columns-3'" class="tile-container">
  <a
    *ngFor="let tile of mainContentSettings.tiles"
    class="tile"
    [attr.href]="tile.externalLink ? tile.externalLink : null"
    [attr.target]="tile.externalLink ? '_blank' : null"
    (click)="tile.internalLink ? navigate(tile.internalLink) : null"
  >
    <img [src]="tile.image" alt="" class="tile-image" />
    <span class="tile-text">{{ tile.titleTranslationKey | translate }}</span>
  </a>
</div>
