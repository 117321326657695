<app-header-with-back-button [titleTranslationKey]="otherCoursesSettings.titleTranslationKey"></app-header-with-back-button>

<div class="other-courses-content">
  <img [src]="otherCoursesSettings.teaserSrc" alt="about us" class="teaser" />
  <p
    *ngFor="let paragraph of otherCoursesSettings.paragraphs; let i = index"
    [innerHTML]="paragraph.textTranslationKey | translate | newLine"
    [class.italic-paragraph]="paragraph.isItalic"
  ></p>
</div>

<mat-accordion class="default-max-width">
  <mat-expansion-panel [togglePosition]="'before'" *ngFor="let row of otherCoursesSettings.table">
    <mat-expansion-panel-header class="extra-height">
      <mat-panel-title>
        <div class="header">
          <h3>{{ row[1][0] }}</h3>
          <span>{{ row[0][0] }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngFor="let subsection of row[2]" class="subsection">{{ subsection }}</div>
    <br />
    <div *ngFor="let subsection of row[3]" class="subsection">{{ subsection }}</div>
    <a [href]="row[4][0]" target="_blank" class="subsection" [class.disabledJoinLink]="!row[0][1]">join event</a>
  </mat-expansion-panel>
</mat-accordion>
