import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../../core/app-config.service';
import { Articles, MediaCoverageConfig } from '../../../../models/config';

@Component({
  selector: 'app-media-coverage',
  templateUrl: './media-coverage.component.html',
  styleUrls: ['./media-coverage.component.scss']
})
export class MediaCoverageComponent implements OnInit {
  mediaCoverageConfig: MediaCoverageConfig;
  articles: Articles[];

  constructor(private config: AppConfigService) {}

  ngOnInit(): void {
    this.mediaCoverageConfig = this.config.mediaCoverageSettings;
    this.articles = this.mediaCoverageConfig.articles.sort((a, b) => (this.newDate(a.date) > this.newDate(b.date) ? -1 : 1));
  }

  private newDate(date: string): Date {
    const [year, month, day] = date.split('-');
    return new Date(+year, +month - 1, +day);
  }
}
