import { Component } from '@angular/core';
import { AppConfigService } from '../../core/app-config.service';
import { LegalConfig } from '../../models/config';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent {
  legalSettings: LegalConfig;

  constructor(private config: AppConfigService) {
    this.legalSettings = this.config.legalSettings;
  }

  getEmailLink(email: string) {
    return 'mailto:<' + email + '>' + email + '?subject=legal';
  }
}
