<app-header-with-back-button [titleTranslationKey]="contactSettings.titleTranslationKey"></app-header-with-back-button>

<mat-accordion class="default-max-width">
  <mat-expansion-panel [togglePosition]="'before'" *ngFor="let contact of contactSettings.contacts">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>
          {{ contact.city }}
        </h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngFor="let detail of contact.addressDetails">{{ detail }}</div>
  </mat-expansion-panel>
</mat-accordion>
