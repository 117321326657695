<app-header-with-back-button [titleTranslationKey]="socialMediaSettings.titleTranslationKey"></app-header-with-back-button>

<div class="social-media-container">
  <div class="market-container">
    <div class="description">{{ socialMediaSettings.marketDescriptionTranslationKey | translate }}</div>
    <a [href]="socialMediaSettings.marketButton.link" target="_blank">
      <img class="google-play-badge clickable" [src]="socialMediaSettings.marketButton.image" alt="google play store"
    /></a>
  </div>

  <div class="links-container">
    <div class="description">{{ socialMediaSettings.linksDescriptionTranslationKey | translate }}</div>
    <div class="buttons">
      <a
        *ngFor="let button of socialMediaSettings.socialMediaButtons"
        [href]="button.link"
        class="social-media-button clickable"
        target="_blank"
      >
        <img [src]="button.image" [alt]="button.name" />
      </a>
    </div>
  </div>
</div>
