import { Component, DestroyRef, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Language } from '../../../models/language';
import { ListProcessingService } from '../../../core/list-processing.service';
import { LanguageService } from '../../../core/language.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  languages$: Observable<Language[]>;
  uiLanguage$: Observable<string>;
  groups: Language[][];
  languages: Language[] = null;

  constructor(
    private listProcessingService: ListProcessingService,
    private languageService: LanguageService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.uiLanguage$ = this.languageService.uiLanguage$;
    this.languages$ = this.languageService.getTranslatedLanguages().pipe(
      tap(res => {
        this.languages = res;
        this.initLanguagesGroups();
      })
    );
  }

  onListClick($event: MouseEvent) {
    const targetId: string = this.listProcessingService.getTargetItem($event, 'ul', 'li');

    if (targetId) {
      const index = parseInt(targetId, 10);
      this.languageService
        .setNewLanguage(this.languages[index].lnCode)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((): void => {
          this.router.navigate(['../']);
        });
    }
  }

  getCurrentLanguage(currentSelectedLnCode: string): Language | undefined {
    if (!this.languages) {
      return undefined;
    }
    return this.languages.find(language => currentSelectedLnCode === language.lnCode);
  }

  private initLanguagesGroups() {
    this.groups = this.languages.reduce((languageGroups: Language[][], language: Language) => {
      if (!languageGroups[language.group]) {
        languageGroups[language.group] = [];
      }
      languageGroups[language.group].push(language);
      return languageGroups;
    }, []);
  }
}
