import { TranslateLoader } from '@ngx-translate/core';
import { StaticTranslationService } from './api/static-translation.service';
import { first, Observable, onErrorResumeNext, tap, throwIfEmpty } from 'rxjs';

export class TranslationLoader implements TranslateLoader {
  constructor(private staticTranslationService: StaticTranslationService) {}

  getTranslation = (lang: string): Observable<any> =>
    onErrorResumeNext([
      this.staticTranslationService
        .getRemoteTranslation(lang)
        .pipe(tap(value => this.staticTranslationService.setLocalTranslation(lang, value))),
      this.staticTranslationService.getLocalTranslation(lang),
      this.staticTranslationService.getLocalFileTranslation()
    ]).pipe(
      throwIfEmpty(() => new Error('No translations could have been loaded from any of the sources')),
      first()
    );
}
