<div class="overall">
  <app-header-with-back-button [backRoute]="['/career']" [backButtonTranslationKey]="'career.title'"></app-header-with-back-button>

  <h3>{{ jobSettings.title }}</h3>

  <ng-container *ngFor="let section of jobSettings.sections">
    <h4>{{ section.title }}</h4>
    <p class="section">
      <ng-container *ngFor="let subsection of section.subsections; let last = last">
        <span *ngIf="section.isDashed" class="dashed">- </span>
        <span [innerHTML]="subsection | newLine" [class.italic]="section.lastItalic && last"></span><br />
      </ng-container>
    </p>
    <span *ngIf="section.footNote" [innerHTML]="section.footNote | newLine"></span>
  </ng-container>
</div>
