import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListProcessingService {
  getTargetItem(event: MouseEvent, parentTagName: string, targetTagName: string): string {
    let source: Element = event.target as Element;
    if (source.tagName.toLocaleLowerCase() === parentTagName) {
      return null;
    }

    while (source.tagName.toLocaleLowerCase() !== targetTagName) {
      source = source.parentElement;
    }
    return source.id;
  }
}
