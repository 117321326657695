import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine'
})
export class NewLinePipe implements PipeTransform {
  private _linkStarted = false;

  transform(value: string, ...args: unknown[]): string {
    this._linkStarted = false;
    const check = value.replace(/\n/g, '<br/>');
    let split = check.split('<br/>');
    split = split.map(el => this.insertAfterEveryN(el, '<br/>', 50));
    return split.join('<br/>');
  }

  private insertAfterEveryN = (str, char, n) => {
    let lineLength = 0;
    return str.split(' ').reduce((accumulator, current) => {
      lineLength += current.length + 1;
      if (current === '<a') {
        this._linkStarted = true;
      }
      if (lineLength < n || this._linkStarted) {
        if (this._linkStarted) {
          if (current.includes('</a>')) {
            this._linkStarted = false;
          }
        }
        return accumulator + current + ' ';
      }
      lineLength = current.length + 1;
      return accumulator + char + current + ' ';
    }, '');
  };
}
