import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './views/top-bar/top-bar.component';
import { BottomBarComponent } from './views/bottom-bar/bottom-bar.component';
import { ContentComponent } from './views/content/content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LanguageSelectorComponent } from './views/top-bar/language-selector/language-selector.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationLoader } from './core/translation.loader';
import { StaticTranslationService } from './core/api/static-translation.service';
import { LanguageService } from './core/language.service';
import { Observable, switchMap } from 'rxjs';
import { Language } from './core/language-list';
import { AppConfigService } from './core/app-config.service';
import { OtherCoursesComponent } from './views/content/tiles/other-courses/other-courses.component';
import { AboutUsComponent } from './views/content/tiles/about-us/about-us.component';
import { LegalComponent } from './views/legal/legal.component';
import { ContactComponent } from './views/contact/contact.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewLinePipe } from './core/new-line.pipe';
import { SocialMediaComponent } from './views/content/tiles/social-media/social-media.component';
import { MediaCoverageComponent } from './views/content/tiles/media-coverage/media-coverage.component';
import { CareerComponent } from './views/content/tiles/career/career.component';
import { MatSelectModule } from '@angular/material/select';
import { CallbackPipe } from './core/callback.pipe';
import { LinkInStringPipe } from './core/link-in-string.pipe';
import { HeaderWithBackButtonComponent } from './core/header-with-backbutton/header-with-back-button.component';
import { JobComponent } from './views/content/tiles/career/job/job.component';

const AppInitializerFactory = (
  languageService: LanguageService,
  appConfigService: AppConfigService
): (() => Observable<Language[]>) => () => {
  localStorage.clear();
  document.cookie = '';
  return appConfigService.init('config.json').pipe(switchMap(() => languageService.init()));
};

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    BottomBarComponent,
    ContentComponent,
    LanguageSelectorComponent,
    OtherCoursesComponent,
    AboutUsComponent,
    LegalComponent,
    ContactComponent,
    NewLinePipe,
    SocialMediaComponent,
    MediaCoverageComponent,
    CareerComponent,
    CallbackPipe,
    LinkInStringPipe,
    HeaderWithBackButtonComponent,
    JobComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
        deps: [StaticTranslationService]
      }
    }),
    MatExpansionModule,
    BrowserAnimationsModule,
    MatSelectModule
  ],
  providers: [{ provide: APP_INITIALIZER, useFactory: AppInitializerFactory, deps: [LanguageService, AppConfigService], multi: true }],
  bootstrap: [AppComponent],
  exports: [CallbackPipe]
})
export class AppModule {}
