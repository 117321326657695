import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ContactConfig } from '../../models/config';
import { AppConfigService } from '../../core/app-config.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  contactSettings: ContactConfig;

  constructor(appConfigService: AppConfigService) {
    this.contactSettings = appConfigService.contactSettings;
  }
}
