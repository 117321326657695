<ng-container *ngIf="!childrenId; else showChildren">
  <app-header-with-back-button [backRoute]="['/']" [titleTranslationKey]="careerSettings.titleTranslationKey"></app-header-with-back-button>
  <div class="button-grid">
    <a class="link" routerLink="how-to-apply">
      <img class="link-icon" src="/assets/career/open_positions.svg" alt="how to apply" /><br />
      how to apply
    </a>
    <a class="link" routerLink="why-akelius">
      <img class="link-icon" src="/assets/career/why_akelius.svg" alt="why Akelius" /><br />
      why Akelius
    </a>
  </div>
</ng-container>

<ng-template #showChildren [ngSwitch]="childrenId">
  <ng-container *ngSwitchCase="1">
    <app-header-with-back-button [backRoute]="['/career']" title="how to apply"></app-header-with-back-button>
    <p>
      Please send us your letter of motivation, together with your CV to
      <a target="_blank" href="mailto:career%40akelius.de">career@akelius.de</a>.
    </p>
    <p>Include in the subject of your email the title of the position you are applying for.</p>

    <h2 class="data-protection-title">general data protection</h2>

    <p>When you apply for a job opening at Akelius, the company will process your personal data.</p>
    <p>The job applications are carefully screened, and only successful candidates will be contacted.</p>
    <p>
      If you would like to know more about how Akelius handles your data in Germany, please see our
      <a
        href="/assets/career/general_information_to_job_applicants_DE_Languages.pdf"
        target="_blank"
        download="Akelius Data Protection information for job applicants DE.pdf"
        >general data protection information</a
      >
      for job applicants.
    </p>
    <p>All applications will be treated in strict confidence, ensuring GDPR compliance.</p>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <app-header-with-back-button [backRoute]="['/career']" title="why Akelius"></app-header-with-back-button>

    <p>As an employee at Akelius you will get</p>
    <ul>
      <li>a unique position in an international and dynamic environment</li>
      <li>the chance to be a key player in a growing, highly skilled team</li>
      <li>
        a collaborative environment fostered by our kind and smart colleagues, representing more than twenty nationalities
      </li>
      <li>team and company events</li>
      <li>Biennale: company outing every two years which is a mix of education, culture, activity and fun</li>
      <li>competitive compensation package</li>
      <li>benefits including a yearly health budget for massage or sport</li>
      <li>yoga sessions in the office</li>
      <li>flexible working hours, possibility of part-time work</li>
    </ul>

    <mat-accordion class="default-max-width">
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Germany
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ul class="">
          <li>choice of phone and computer</li>
          <li>30 days of annual holidays</li>
          <li>bright and modern offices in the heart of Berlin</li>
          <li>open kitchen area where you can cook, along with free drinks, snacks and fruit</li>
          <li>discounted BVG company ticket</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Cyprus
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ul class="">
          <li>choice of computer</li>
          <li>21 days of annual holidays</li>
          <li>bright and modern offices in the heart of Nicosia</li>
          <li>open kitchen area where you can cook, along with free drinks, snacks and fruit</li>
          <li>access to free parking at or near the office</li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</ng-template>
