<app-header-with-back-button [titleTranslationKey]="legalSettings.titleTranslationKey"></app-header-with-back-button>

<div class="legal-content">
  <div *ngFor="let section of legalSettings.sections">
    <div *ngFor="let subSection of section">
      <span *ngIf="!subSection.endsWith('@akelius.com')">{{ subSection }}</span>
      <a *ngIf="subSection.endsWith('@akelius.com')" [href]="getEmailLink(subSection)" target="_blank">{{ subSection }}</a>
    </div>
    <br />
  </div>
</div>
