import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../../../../core/app-config.service';
import { JobConfig } from '../../../../../models/config';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit, OnDestroy {
  jobSettings: JobConfig;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private config: AppConfigService) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this._destroy$)).subscribe(({ jobKey }) => {
      this.jobSettings = this.config.getJobSettings(jobKey);
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }
}
