import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LanguageSelectorComponent } from './views/top-bar/language-selector/language-selector.component';
import { ContentComponent } from './views/content/content.component';
import { OtherCoursesComponent } from './views/content/tiles/other-courses/other-courses.component';
import { AboutUsComponent } from './views/content/tiles/about-us/about-us.component';
import { LegalComponent } from './views/legal/legal.component';
import { ContactComponent } from './views/contact/contact.component';
import { MediaCoverageComponent } from './views/content/tiles/media-coverage/media-coverage.component';
import { SocialMediaComponent } from './views/content/tiles/social-media/social-media.component';
import { CareerComponent } from './views/content/tiles/career/career.component';
import { JobComponent } from './views/content/tiles/career/job/job.component';

const routes: Routes = [
  {
    path: '',
    component: ContentComponent
  },
  {
    path: 'languages',
    component: LanguageSelectorComponent,
    title: 'Home language'
  },
  {
    path: 'otherCourses',
    component: OtherCoursesComponent,
    title: 'Other courses'
  },
  {
    path: 'aboutUs',
    component: AboutUsComponent,
    title: 'About us'
  },
  {
    path: 'legal',
    component: LegalComponent,
    title: 'Legal'
  },
  {
    path: 'contact',
    component: ContactComponent,
    title: 'Contact'
  },
  {
    path: 'media-coverage',
    component: MediaCoverageComponent,
    title: 'Media coverage'
  },
  {
    path: 'socialMedia',
    component: SocialMediaComponent,
    title: 'Online Channels'
  },
  {
    path: 'career',
    component: CareerComponent,
    title: 'Work in Akelius',
    children: [
      { pathMatch: 'full', path: 'how-to-apply', title: 'How to apply', data: { id: 1 } },
      { pathMatch: 'full', path: 'why-akelius', title: 'Why Akelius', data: { id: 2 } }
    ]
  },
  {
    path: 'jobs/:jobKey',
    component: JobComponent,
    title: 'Jobs'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
