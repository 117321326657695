import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../../core/app-config.service';
import { TopBarConfig } from '../../models/config';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  topBarSettings: TopBarConfig;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private config: AppConfigService) {
    this.topBarSettings = config.topBarSettings;
  }

  openLanguages() {
    this.router.navigate(['languages'], { relativeTo: this.activatedRoute });
  }
}
