<div class="holder">
  <app-header-with-back-button [backRoute]="['../']" [titleTranslationKey]="'uiLanguageModal.title'"></app-header-with-back-button>

  <div class="current-selected-language" *ngIf="uiLanguage$ | async as uiLanguage">
    <span>{{ uiLanguage ? getCurrentLanguage(uiLanguage)?.nativeName : '' }}</span>
  </div>

  <hr />

  <div class="languages-container" *ngIf="languages$ | async">
    <span class="no-results" *ngIf="groups.length === 0">
      {{ 'dictionary.noResult| translate ' }}
    </span>
    <ul
      *ngFor="let group of groups"
      class="languages-list"
      (click)="onListClick($event)"
      (keyup.enter)="onListClick($event)"
      data-cy="languages-list"
    >
      <li
        tabindex="1"
        class="language-item"
        *ngFor="let lang of group; let i = index"
        [id]="languages.indexOf(lang)"
        [attr.data-cy]="'select-language-' + lang.lnCode"
      >
        <span class="language-name">{{ lang.nativeName }}</span>
      </li>
    </ul>
  </div>
</div>
