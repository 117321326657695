import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CareerConfig,
  Config,
  ContactConfig,
  LegalConfig,
  MainContentConfig,
  MediaCoverageConfig,
  OtherCoursesConfig,
  SocialMediaConfig,
  TopBarConfig
} from '../models/config';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private _config: Config;

  constructor(private http: HttpClient) {}

  get dictionaryUrl(): string {
    return this._config.dictionaryUrl;
  }

  get translationsUrl(): string {
    return this._config.translationsUrl;
  }

  get mainContentSettings(): MainContentConfig {
    return this._config.mainContentSettings;
  }

  get legalSettings(): LegalConfig {
    return this._config.legalSettings;
  }

  get mediaCoverageSettings(): MediaCoverageConfig {
    return this._config.mediaCoverageSettings;
  }

  get otherCoursesSettings(): OtherCoursesConfig {
    return this._config.otherCoursesSettings;
  }

  get careerSettings(): CareerConfig {
    return this._config.careerSettings;
  }

  get topBarSettings(): TopBarConfig {
    return this._config.topBarSettings;
  }

  get contactSettings(): ContactConfig {
    return this._config.contactSettings;
  }

  get socialMediaSettings(): SocialMediaConfig {
    return this._config.socialMediaSettings;
  }

  init = (configUrl: string) => {
    const params = new HttpParams().set('v', `${new Date().getTime()}`);
    return this.http
      .get<Config>(configUrl, { params })
      .pipe(tap(res => (this._config = res)));
  };

  getJobSettings(jobKey: string) {
    return this._config.jobs[jobKey];
  }
}
