import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../../../core/app-config.service';
import { OtherCoursesConfig } from '../../../../models/config';

@Component({
  selector: 'app-other-courses',
  templateUrl: './other-courses.component.html',
  styleUrls: ['./other-courses.component.scss']
})
export class OtherCoursesComponent implements OnInit {
  otherCoursesSettings: OtherCoursesConfig;

  constructor(private config: AppConfigService) {}

  ngOnInit() {
    this.otherCoursesSettings = this.config.otherCoursesSettings;
  }

  getEmailLink(email: string) {
    return 'mailto:<' + email + '>' + email + '?subject=contact';
  }
}
