import { Component } from '@angular/core';
import { SocialMediaConfig } from '../../../../models/config';
import { AppConfigService } from '../../../../core/app-config.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {
  socialMediaSettings: SocialMediaConfig;

  constructor(private config: AppConfigService) {
    this.socialMediaSettings = this.config.socialMediaSettings;
  }
}
