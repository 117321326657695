<app-header-with-back-button [titleTranslationKey]="mediaCoverageConfig.titleTranslationKey"></app-header-with-back-button>

<div class="media-coverage-content">
  <div *ngFor="let article of articles" class="article">
    <div class="image-content">
      <img [src]="article.image" class="article-image" [class.no-image]="!article.image" [alt]="article.imageText" />
      <span>{{ article.imageText }}</span>
    </div>
    <div class="article-content">
      <p class="article-title">{{ article.title }}</p>
      <span class="meta-info">
        {{ article.date }}
        <span *ngIf="article.source"> | </span>
        <span>{{ article.source }}</span>
      </span>
      <p class="article-description">
        {{ article.description }}<span *ngIf="article.description">... </span>
        <a [href]="article.link" target="_blank" class="read-more" *ngIf="article.link">{{
          (!article.isVideo ? mediaCoverageConfig.readMoreTranslationKey : mediaCoverageConfig.watchVideoTranslationKey) | translate
        }}</a>
        <br />
        <ng-container *ngIf="article.withPdf">
          <span *ngIf="article.link">{{ mediaCoverageConfig.withPdfPrefixTranslationKey | translate }} </span>
          <a target="_blank" class="read-more" [href]="'assets/media-coverage/' + article.withPdf">
            {{ mediaCoverageConfig.withPdfTranslationKey | translate }}
          </a>
        </ng-container>
      </p>
    </div>
  </div>
</div>

<!-- test test -->
